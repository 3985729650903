import React from 'react'




const Dropdown = (props) => {

    const generateHours = (len) => {
        let listOptions = []
        for (let i = 1; i <= len; i++){
            if (i === 7) {
                listOptions.push(<option selected key={i} value={i}>{i}</option>)
            }
            else {
                listOptions.push(<option key={i} value={i}>{i}</option>)
            }
            
        }
        return listOptions
    }
    
    const generateMinutes = (len) => {
        let listOptions = []
        listOptions.push(<option key={0} value="00">00</option>)
        listOptions.push(<option key={5} value="05">05</option>)
        for (let i = 10; i <= len; i += 5){
            listOptions.push(<option key={i} value={i}>{i}</option>)
        }
        return listOptions
    }


    return (
        <div className="container mx-auto my-2 bg-gray-100">
            <div className="inline-flex text-lg border rounded-md shadow-lg p-2">
                <select onChange={props.hourHandler}name="" id="" className="px-2 outline-none appearance-none bg-transparent">
                    {generateHours(12)}      
                </select>
                <span className="px-2">:</span>
                <select onChange={props.minuteHandler} name="" id="" className="px-2 outline-none appearance-none bg-transparent">
                    {generateMinutes(55)}
                </select>
                <select onChange={props.AMPMHandler} name="" id="" className="px-2 outline-none appearance-none bg-transparent">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                </select>
            </div>
        </div>
    )
}

export default Dropdown;



