import React, { useEffect} from 'react'
import SleepTimes from './sleepTimes'
import Dropdown from './dropdown'
import {convertTimeStringToDateObj} from '../timeUtil'


const GotoSleepAt = (props) => {
    const [wakeupHour, setWakeupHour] = React.useState("7");
    const [wakeupMinute, setWakeupMinute] = React.useState("00");
    const [wakeupAMPM, setWakeupAMPM] = React.useState("AM");
    const [dateObj, setDateObj] = React.useState(new Date().setSeconds(0))

    useEffect(() => {
        setDateObj(updateTimeString(wakeupHour, wakeupMinute, wakeupAMPM)); 
    }, [wakeupHour, wakeupMinute, wakeupAMPM]);


    const updateTimeString = (hour, minute, ampm) => {
        let timeString = `${hour}:${minute}${ampm}`
        return convertTimeStringToDateObj(timeString)
    }

    const handleHourChange = (event) => {
        setWakeupHour(event.target.value)
    }

    const handleMinuteChange = (event) => {
        setWakeupMinute(event.target.value)
    }

    const handleAMPMChange = (event) => {
        setWakeupAMPM(event.target.value)
    }

    return (
        <div>
            <Dropdown hourHandler={handleHourChange} minuteHandler={handleMinuteChange} AMPMHandler={handleAMPMChange}/>
            <SleepTimes wakeUp={true} refresh={false} time={dateObj}/>
        </div>
    )
}

export default GotoSleepAt;