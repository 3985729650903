import React from 'react'
import './css/aurora.scss'
import Tabs from './components/Tabs';

function App() {

    return (
      <div>
        <div className="aura-bg"></div>
        <div className="aura-content">
          <br>
          </br>
          <span className="text-4xl pb-font-semibold text-white font-bold opacity-100">I want to..</span>
          <Tabs color=""/>
        </div>
      </div>

    )
}

export default App;
