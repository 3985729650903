import React from 'react'
import SleepTime from './sleepTime'
import * as SleepFn from '../timeUtil'

const SleepTimes = (props) => {
    const [currentTime, setCurrentTime] = React.useState(new Date().setSeconds(0));

      // const colorList = [
        //     "text-4xl font-semibold text-amber-500 font-bold opacity-100",
        //     "text-4xl font-semibold text-amber-400 font-bold opacity-100",
        //     "text-4xl font-semibold text-amber-300 font-bold opacity-100",
        //     "text-4xl font-semibold text-amber-200 font-bold opacity-100",
        //     "text-4xl font-semibold text-amber-100 font-bold opacity-100",
        //     "text-4xl font-semibold text-white font-bold opacity-100"

        // ]

    React.useEffect(() => {
        setCurrentTime(props.time);
        }, [currentTime]);
    
    const colorList = [
        "text-4xl animate-fade-in-down font-semibold text-red-600 font-bold opacity-90",
        "text-4xl animate-fade-in-down-1 font-semibold text-red-500 font-bold opacity-100",
        "text-4xl animate-fade-in-down-2 font-semibold text-amber-300 font-bold opacity-100",
        "text-4xl animate-fade-in-down-3 font-semibold text-amber-200 font-bold opacity-100",
        "text-4xl animate-fade-in-down-4 font-semibold text-amber-100 font-bold opacity-100",
        "text-4xl animate-fade-in-down-5 font-semibold text-white font-bold opacity-100"

    ]
    // const currentTime = new Date().setSeconds(0);
    const times = props.wakeUp ? SleepFn.generateWakeUpTimes(props.time, 6, 'en-US') : SleepFn.generateSleepTimes(props.time, 6, 'en-US')
    const sleepTimeList = times.map((t, i) => 
        <SleepTime time={t} color={colorList[i]} key={i} />)
        
    return(
        <div className="flex-col space-y-2 ">
            <span className="text-4xl font-semibold text-white font-bold opacity-100">{props.wakeUp ? "You should wake up at..." : "You should go to bed at..."} </span>
            {sleepTimeList}

            {props.refresh ? <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => setCurrentTime(new Date().setSeconds(0))}>Refresh</button> : <button className="bg-blue-500 hover:bg-blue-700 hidden text-white font-bold py-2 px-4 rounded-full" onClick={() => setCurrentTime(new Date().setSeconds(0))}>Refresh</button>}
            
            {/* <SleepTime time="9:00" color="text-4xl font-semibold text-emerald-700 font-bold opacity-100" />
            <SleepTime time="9:00" color="text-4xl font-semibold text-emerald-600 font-bold opacity-100" />
            <SleepTime time="9:00" color="text-4xl font-semibold text-emerald-500 font-bold opacity-100" />
            <SleepTime time="9:00" color="text-4xl font-semibold text-emerald-400 font-bold opacity-100" />
            <SleepTime time="9:00" color="text-4xl font-semibold text-lime-200 font-bold opacity-100" />
            <SleepTime time="9:00" color="text-4xl font-semibold text-lime-100 font-bold opacity-100" /> */}
        </div>
    )
    
    
}

export default SleepTimes;