import React from 'react'
import SleepTimes from './sleepTimes'

const GotoBedNow = (props) => {

    return (
        <div>
            <span className="text-4xl pb-font-semibold text-white font-bold opacity-100">If you go to bed now</span>
            {<SleepTimes wakeUp={true} refresh={true} time={new Date().setSeconds(0)}/>}
        </div>
        )
}

export default GotoBedNow