import React from "react";
import GotoBedNow from "./GotoBedNow";
import WakeUpAt from "./WakeUpAt";
import GotoSleepAt from "./GotoSleepAt";

const Tabs = ({ color }) => {
  const [openTab, setOpenTab] = React.useState(2);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div>
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 pb-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-base font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-" + color + "-600 bg-white"
                      : "text-white bg-" + color + "-600")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Go to bed now
                </a>
              </li>
              <li className="-mb-px mr-2 pb-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-base font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-" + color + "-600 bg-white"
                      : "text-white bg-" + color + "-600")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Wake up at
                </a>
              </li>
              <li className="-mb-px mr-2 pb-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-base font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                      ? "text-" + color + "-600 bg-white"
                      : "text-white bg-" + color + "-600")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Go to sleep at
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 ">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                    <GotoBedNow />
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    <WakeUpAt />
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    <GotoSleepAt />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
        </div>
    </>
  );
};

export default Tabs
