const addMinutesToDate = (date, minutes) => {
    return new Date(new Date(date).getTime() + minutes * 60000);
}

const subtractMinutesToDate = (date, minutes) => {
    return new Date(new Date(date).getTime() - minutes * 60000);
}

export const generateWakeUpTimes = (currentTime, numberOfTimes, format) => {
    const wakeUpTimes = []
    const fallAsleepDelay = 15
    const cycleInterval = 90
    
    if(currentTime) {
        wakeUpTimes.push(addMinutesToDate(currentTime, (fallAsleepDelay + cycleInterval)));

        for(let i = 0; i < (numberOfTimes - 1); i++){
            wakeUpTimes.push(addMinutesToDate(wakeUpTimes[i], cycleInterval));
        }
    }
    else {
        console.error("no Date object passed");
    }

    return wakeUpTimes.map(x => x.toLocaleTimeString(format,{ hour: 'numeric', minute: '2-digit' }));
}

export const convertTimeStringToDateObj = (timeString) => {
    let date = new Date();
    let s = /(\d+):(\d+)(.+)/.exec(timeString);
    date.setHours(s[3] === "PM" ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10));
    date.setMinutes(parseInt(s[2],10));
    date.setSeconds(0);
    return date
}

export const generateSleepTimes = (wakeUpTime, numberOfTimes, format) => {
    const fallAsleepTimes = []
    const fallAsleepDelay = 15
    const cycleInterval = 90
    if(wakeUpTime) {
        fallAsleepTimes.push(subtractMinutesToDate(wakeUpTime, (fallAsleepDelay + cycleInterval)));

        for(let i = 0; i < (numberOfTimes - 1); i++){
            fallAsleepTimes.push(subtractMinutesToDate(fallAsleepTimes[i], cycleInterval));
        }
    }
    else {
        console.error("no Date object passed");
    }
    

    return fallAsleepTimes.map(x => x.toLocaleTimeString(format,{ hour: 'numeric', minute: '2-digit' }));
}

// const a = generateWakeUpTimes(new Date(), 6, 'en-US')
// const b = generateSleepTimes(new Date(), 6, 'en-US')
// // console.log(a[0].toString())
// b.forEach(ele => console.log(ele))
